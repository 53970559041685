<template>
  <div class="login">
    <div class="header">
      <div class="container">
        <div class="logo"><img src="../assets/logo.png" alt="众为资本" /></div>
        <ChangeLanguage />
      </div>
    </div>
    <FirstLogin :email="ruleForm.email" :resetPasswdCode="resetPasswdCode" v-if="isFirstLogin" />
    <div class="form-box" v-else>
      <h1>{{ $t('login.title') }}</h1>
      <el-form :model="ruleForm" ref="ruleForm" class="form-login">
        <el-form-item
          prop="email"
          :rules="[
            { required: true, message: $t('login.emailError'), trigger: 'blur' },
            { type: 'email', message: $t('login.emailError2'), trigger: ['blur', 'change'] },
          ]"
        >
          <el-input v-model="ruleForm.email" :placeholder="$t('login.email')" class="email"></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          :rules="[
            { required: true, message: $t('login.passwordError'), trigger: 'blur' },
          ]"
        >
          <el-input type="password" :maxlength="32" :placeholder="$t('login.password')" class="password" v-model="ruleForm.password" autocomplete="off"></el-input>
          <div class="input-suffix"><router-link to="/resetpassword">{{ $t('login.forgetPassword') }}</router-link></div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="btn-block" @click="submitForm('ruleForm')">{{ $t('login.title') }}</el-button>
          <!-- <div class="other-login">验证码登录</div> -->
        </el-form-item>
        <div class="form-tips">{{ $t('login.tip1') }} <a href="javascript:;" @click="dialogAgreement = true">{{ $t('login.tip2') }}</a></div>
      </el-form>
    </div>
    <Footer/>
    
    <el-dialog :visible.sync="dialogAgreement" width="80%">
      <div class="agreement">
        <div class="agreement-content">
          <PrivacyText :lang="lang" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import ChangeLanguage from '@/components/ChangeLanguage.vue'
import FirstLogin from '@/components/login/FirstLogin'
import Footer from '@/layout/components/Footer.vue'
import PrivacyText from "@/components/Privacy.vue"

export default {
  components: {
    ChangeLanguage,
    FirstLogin,
    Footer,
    PrivacyText
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
  },
  data() {
    return {
      ruleForm: {
        email: '',
        password: '',
      },
      isFirstLogin: false,
      resetPasswdCode: '',
      dialogAgreement: false
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.login(this.ruleForm).then((res) => {
            console.log(res)
            const { isFirstLogin, resetPasswdCode, token, userInfo } = res.data
            if (isFirstLogin) {
              this.isFirstLogin = true
              this.resetPasswdCode = resetPasswdCode
            } else if (token) {
              localStorage.setItem('token', token)
              localStorage.setItem('userInfo', JSON.stringify(userInfo))
              this.$router.push('/home')
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
