<template>
  <div>
    <h1>免责声明</h1>
    <h2>内容所有权</h2>
    <p>本网站的一切版权，以及与本网站相关的所有信息内容，包括但不限于：文字表述及其组合、图片、图表、视频、商标、图标图饰、界面设计、版面框架、有关数据、电子文档等均受版权、商标和其它财产所有权法律的保护。除众为资本授权许可协议所提供的明确书面许可外，用户使用本网站并不赋予用户任何有关这些专利、商标、版权或其它知识产权的许可。</p>
    <h2>免责声明</h2>
    <p>用户明确同意其使用本网站所存在的风险将完全由其自己承担；因其使用本网站而产生的一切后果也由其自己承担，众为资本对用户不承担任何责任。本网站不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。本网站不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该外部链接指向的不由本网站实际控制的任何网页上的内容，本网站不承担任何责任。凡通过本平台与其他平台的链接，而获得其他平台所提供的网上资料、内容及服务，您应该自己进行辨别及判断，并应当由该平台对其提供内容承担相应责任，本网站对此不承担任何责任。本平台提供链接至其它平台，并不视为同意、推荐、认可、保证或推介任何第三方或在其平台所提供的服务/产品，投资者应当审慎考虑有关风险，众为资本不承担任何责任。对于因不可抗力或本网站不能控制的原因造成的网络服务中断或其它缺陷，本网站不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  props: {
    lang: String,
  },
}
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 20px;
  color: #000;
  text-align: center;
}
h2 {
  margin: 15px 0;
  font-size: 16px;
  font-weight: bold;
  color: #2d2d2d;
}
ul {
  padding-left: 20px;
  list-style-type: disc;
}
p {
  margin-bottom: 10px;
  line-height: 20px;
}
</style>
