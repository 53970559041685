<template>
  <div class="form-box first-login">
      <h2 class="title">{{ $t('login.firstLogin') }}</h2>
      <el-form :model="ruleForm" ref="ruleForm" class="form-login">
        <div class="form-email">
          <div class="email-value">{{email}}</div>
          <div class="tips">{{ $t('login.firstLoginTip') }}</div>
        </div>
        <el-form-item
        prop="password" 
        :rules="[
          { required: true, message: $t('login.passwordError'), trigger: 'blur' },
          { pattern: /^[A-Za-z0-9]{7,32}$/, message: $t('login.passwordTip') }
        ]">
          <el-input type="password" :maxlength="32" :placeholder="$t('login.password')" class="password" v-model="ruleForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <div class="input-tips">{{ $t('login.passwordTip') }}</div>
        <el-form-item>
          <el-button type="primary" class="btn-block" @click="submitForm('ruleForm')">{{ $t('login.submit') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>
import api from "@/api"

export default {
  name: 'FirstLogin',
  props: {
    email: {
      type: String,
      default: ''
    },
    resetPasswdCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ruleForm: {
        password: '',
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
          api.fristLoginResetPasswd({
            email: this.email,
            code: this.resetPasswdCode,
            password: this.ruleForm.password
          }).then(res => {
            console.log(res)
            const { token, userInfo } = res.data
            localStorage.setItem('token', token)
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            this.$router.push('/home')
          })
        } else {
          return false
        }
      })
    },
  }
}
</script>