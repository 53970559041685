var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('ChangeLanguage')],1)]),(_vm.isFirstLogin)?_c('FirstLogin',{attrs:{"email":_vm.ruleForm.email,"resetPasswdCode":_vm.resetPasswdCode}}):_c('div',{staticClass:"form-box"},[_c('h1',[_vm._v(_vm._s(_vm.$t('login.title')))]),_c('el-form',{ref:"ruleForm",staticClass:"form-login",attrs:{"model":_vm.ruleForm}},[_c('el-form-item',{attrs:{"prop":"email","rules":[
          { required: true, message: _vm.$t('login.emailError'), trigger: 'blur' },
          { type: 'email', message: _vm.$t('login.emailError2'), trigger: ['blur', 'change'] },
        ]}},[_c('el-input',{staticClass:"email",attrs:{"placeholder":_vm.$t('login.email')},model:{value:(_vm.ruleForm.email),callback:function ($$v) {_vm.$set(_vm.ruleForm, "email", $$v)},expression:"ruleForm.email"}})],1),_c('el-form-item',{attrs:{"prop":"password","rules":[
          { required: true, message: _vm.$t('login.passwordError'), trigger: 'blur' },
        ]}},[_c('el-input',{staticClass:"password",attrs:{"type":"password","maxlength":32,"placeholder":_vm.$t('login.password'),"autocomplete":"off"},model:{value:(_vm.ruleForm.password),callback:function ($$v) {_vm.$set(_vm.ruleForm, "password", $$v)},expression:"ruleForm.password"}}),_c('div',{staticClass:"input-suffix"},[_c('router-link',{attrs:{"to":"/resetpassword"}},[_vm._v(_vm._s(_vm.$t('login.forgetPassword')))])],1)],1),_c('el-form-item',[_c('el-button',{staticClass:"btn-block",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v(_vm._s(_vm.$t('login.title')))])],1),_c('div',{staticClass:"form-tips"},[_vm._v(_vm._s(_vm.$t('login.tip1'))+" "),_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.dialogAgreement = true}}},[_vm._v(_vm._s(_vm.$t('login.tip2')))])])],1)],1),_c('Footer'),_c('el-dialog',{attrs:{"visible":_vm.dialogAgreement,"width":"80%"},on:{"update:visible":function($event){_vm.dialogAgreement=$event}}},[_c('div',{staticClass:"agreement"},[_c('div',{staticClass:"agreement-content"},[_c('PrivacyText',{attrs:{"lang":_vm.lang}})],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":"众为资本"}})])
}]

export { render, staticRenderFns }